<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center mb-4">
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"
                    >
                        <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes('auditoria-query-export') &&
                                !permisos.includes(
                                    'auditoria-query-export-and-email'
                                )
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes('auditoria-query-export')
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'auditoria-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="mt-5">
                    <b-row class="mb-4">
                        <b-col cols="12" sm="3">
                            <b-form-group>
                                <label for="search_type"
                                    >Tipo de consulta</label
                                >
                                <b-form-select
                                    id="search_type"
                                    v-model="$v.body_search.search_type.$model"
                                    text-field="descripcion"
                                    value-field="id"
                                    :options="search_types"
                                    placeholder="Tipo de consulta"
                                    autocomplete="null"
                                    @change="onChangeTypeSearch"
                                >
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4" v-if="body_search.search_type === 0">
                        <b-col cols="12" sm="4">
                            <b-form-group>
                                <label for="fecha_inicio">Fecha</label>
                                <b-input-group>
                                    <b-form-input
                                        id="fecha_inicio"
                                        v-model="
                                            $v.body_search.fecha_inicio.$model
                                        "
                                        type="text"
                                        placeholder="AAAA-MM-DD"
                                        :state="validateState('fecha_inicio')"
                                        autocomplete="null"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            v-model="
                                                $v.body_search.fecha_inicio
                                                    .$model
                                            "
                                            button-only
                                            right
                                            locale="en-US"
                                            :state="
                                                validateState('fecha_inicio')
                                            "
                                            aria-controls="fecha_inicio"
                                        ></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-form-invalid-feedback
                                    :state="
                                        $v.body_search.fecha_inicio.formatDate
                                    "
                                >
                                    Formato de fecha inválido
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    :state="
                                        $v.body_search.fecha_inicio.compareDates
                                    "
                                >
                                    La fecha inicial no puede ser mayor a la
                                    fecha final
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="4">
                            <div>
                                <label for="example-input">Hora inicio</label>
                                <b-input-group class="mb-3">
                                    <b-form-input
                                        id="hora-inicio"
                                        v-model="
                                            $v.body_search.hora_inicio.$model
                                        "
                                        type="text"
                                        placeholder="HH:mm"
                                        :state="validateState('hora_inicio')"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-timepicker
                                            v-model="
                                                $v.body_search.hora_inicio
                                                    .$model
                                            "
                                            button-only
                                            right
                                            locale="en"
                                            aria-controls="hora-inicio"
                                            :hour12="false"
                                            no-close-button
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback
                                        v-if="!$v.body_search.hora_inicio.valid"
                                    >
                                        Formato de hora inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.body_search.hora_inicio
                                                .greaterThan
                                        "
                                    >
                                        La hora de inicio no puede ser mayor a
                                        la hora final
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="4">
                            <div>
                                <label for="example-input">Hora Final</label>
                                <b-input-group class="mb-3">
                                    <b-form-input
                                        id="hora-final"
                                        v-model="
                                            $v.body_search.hora_final.$model
                                        "
                                        type="text"
                                        placeholder="HH:mm"
                                        :state="validateState('hora_final')"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-timepicker
                                            v-model="
                                                $v.body_search.hora_final.$model
                                            "
                                            button-only
                                            right
                                            locale="en"
                                            aria-controls="hora-final"
                                            :hour12="false"
                                            no-close-button
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback
                                        v-if="!$v.body_search.hora_final.valid"
                                    >
                                        Formato de hora inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.body_search.hora_inicio.lessThan
                                        "
                                    >
                                        La hora de final no puede ser menor a la
                                        hora de inicio
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4" v-if="body_search.search_type === 1">
                        <b-col cols="12" sm="6">
                            <b-form-group>
                                <label for="fecha_inicio">Fecha Inicio</label>
                                <b-input-group>
                                    <b-form-input
                                        id="fecha_inicio"
                                        v-model="
                                            $v.body_search.fecha_inicio.$model
                                        "
                                        type="text"
                                        placeholder="AAAA-MM-DD"
                                        :state="validateState('fecha_inicio')"
                                        autocomplete="null"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            v-model="
                                                $v.body_search.fecha_inicio
                                                    .$model
                                            "
                                            button-only
                                            right
                                            locale="en-US"
                                            :state="
                                                validateState('fecha_inicio')
                                            "
                                            aria-controls="fecha_inicio"
                                        ></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-form-invalid-feedback
                                    :state="
                                        $v.body_search.fecha_inicio.formatDate
                                    "
                                >
                                    Formato de fecha inválido
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    :state="
                                        $v.body_search.fecha_inicio.compareDates
                                    "
                                >
                                    La fecha inicial no puede ser mayor a la
                                    fecha final
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6">
                            <b-form-group>
                                <label for="fecha_inicio">Fecha Final</label>
                                <b-input-group>
                                    <b-form-input
                                        id="fecha_final"
                                        v-model="
                                            $v.body_search.fecha_final.$model
                                        "
                                        type="text"
                                        placeholder="AAAA-MM-DD"
                                        :state="validateState('fecha_final')"
                                        autocomplete="null"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            v-model="
                                                $v.body_search.fecha_final
                                                    .$model
                                            "
                                            button-only
                                            right
                                            locale="en-US"
                                            :state="
                                                validateState('fecha_final')
                                            "
                                            aria-controls="fecha_final"
                                        ></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-form-invalid-feedback
                                    :state="
                                        $v.body_search.fecha_final.formatDate
                                    "
                                >
                                    Formato de fecha inválido
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    :state="
                                        $v.body_search.fecha_final.compareDates
                                    "
                                >
                                    La fecha inicial no puede ser mayor a la
                                    fecha final
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <!-- <b-col cols="12" sm="3">
                            <div>
                                <label for="example-input">Hora inicio</label>
                                <b-input-group class="mb-3">
                                    <b-form-input
                                        id="hora-inicio"
                                        v-model="
                                            $v.body_search.hora_inicio.$model
                                        "
                                        type="text"
                                        placeholder="HH:mm"
                                        :state="validateState('hora_inicio')"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-timepicker
                                            v-model="
                                                $v.body_search.hora_inicio
                                                    .$model
                                            "
                                            button-only
                                            right
                                            locale="en"
                                            aria-controls="hora-inicio"
                                            :hour12="false"
                                            no-close-button
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback
                                        v-if="!$v.body_search.hora_inicio.valid"
                                    >
                                        Formato de hora inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.body_search.hora_inicio
                                                .greaterThan
                                        "
                                    >
                                        La hora de inicio no puede ser mayor a
                                        la hora final
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="3">
                            <div>
                                <label for="example-input">Hora Final</label>
                                <b-input-group class="mb-3">
                                    <b-form-input
                                        id="hora-final"
                                        v-model="
                                            $v.body_search.hora_final.$model
                                        "
                                        type="text"
                                        placeholder="HH:mm"
                                        :state="validateState('hora_final')"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-form-timepicker
                                            v-model="
                                                $v.body_search.hora_final.$model
                                            "
                                            button-only
                                            right
                                            locale="en"
                                            aria-controls="hora-final"
                                            :hour12="false"
                                            no-close-button
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback
                                        v-if="!$v.body_search.hora_final.valid"
                                    >
                                        Formato de hora inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.body_search.hora_inicio.lessThan
                                        "
                                    >
                                        La hora de final no puede ser menor a la
                                        hora de inicio
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </div>
                        </b-col> -->
                    </b-row>
                    <b-row class="mt-4">
                        <b-col cols="12" sm="4">
                            <b-form-group>
                                <label for="modulo_id">Tabla de consulta</label>
                                <b-form-select
                                    id="modulo_id"
                                    v-model="$v.body_search.modulo_id.$model"
                                    text-field="nombre"
                                    value-field="id"
                                    :options="modulos"
                                    placeholder="Tabla de consulta"
                                    autocomplete="null"
                                >
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="4">
                            <b-form-group>
                                <label for="accion">Acción</label>
                                <b-form-select
                                    id="accion"
                                    v-model="$v.body_search.accion.$model"
                                    text-field="descripcion"
                                    value-field="id"
                                    :options="acciones"
                                    placeholder="Acción registrada"
                                    autocomplete="null"
                                >
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="4">
                            <b-form-group>
                                <label for="username">Nombre de usuario</label>
                                <b-form-input
                                    id="username"
                                    v-model="$v.body_search.username.$model"
                                    type="text"
                                    placeholder="nombre del usuario a buscar"
                                    autocomplete="null"
                                ></b-form-input>
                            </b-form-group>
                            <!-- :state="validateState('username')" -->
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col cols="12">
                            <b-form-group>
                                <label for="filter-input"
                                    >Texto adicional para buscar</label
                                >
                                <b-form-input
                                    id="filter-input"
                                    type="text"
                                    placeholder="Escriba algún texto para buscar"
                                    v-model="$v.body_search.term.$model"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="float-right mb-5">
                        <b-button
                            variant="primary"
                            size="md"
                            class="mt-4 d-flex align-items-center"
                            v-b-popover.hover.top="'Presione ctrl+b o cmd+b'"
                            @click="getAuditorias()"
                        >
                            <feather
                                type="search"
                                size="1.1rem"
                                class="mr-2 text-blue-active"
                            ></feather>
                            <span>Consultar</span>
                        </b-button>
                    </div>
                </div>
                <div class="table-responsive mt-5">
                    <b-table
                        :fields="fields"
                        :items="auditorias"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        head-variant="light"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(ip)="data">
                            <span
                                :class="`badge bg-light-success ${
                                    Theme === 'dark' && 'text-gray'
                                }`"
                            >
                                <feather
                                    type="hash"
                                    size="0.8rem"
                                    class="mr-1"
                                />
                                {{ data.value }}
                            </span>
                        </template>
                        <template #cell(accion)="data">
                            <span class="font-weight-bold">
                                {{ data.value }}
                            </span>
                        </template>
                        <template #cell(modulo)="data">
                            <span>
                                {{ data.value.nombre }}
                            </span>
                        </template>
                        <template #cell(usuario)="data">
                            <span>
                                {{ data.value.username }}
                            </span>
                        </template>
                        <template #cell(fecha)="data">
                            <span>
                                {{ data.item.formated_date }}
                            </span>
                        </template>
                        <template #cell(acciones)="data">
                            <feather
                                type="eye"
                                role="button"
                                @click="
                                    $router.push({
                                        name: 'Ver auditoría',
                                        params: { auditoria_id: data.item.id }
                                    })
                                "
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'auditoria-query-export-and-email'
                                    )
                                "
                                type="mail"
                                role="button"
                                class="ml-2"
                                v-b-popover.hover.top="'Enviar por correo'"
                                @click="sendSingleAuditoria(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="mail"
                                role="button"
                                class="ml-2 text-secondary"
                                v-b-popover.hover.top="'Enviar por correo'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'factura-venta-find-by-id'
                                    )
                                "
                                type="printer"
                                role="button"
                                v-b-popover.hover.top="'Imprimir'"
                                class="ml-2"
                                @click="printAuditoria(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="printer"
                                role="button"
                                class="text-secondary ml-2"
                                v-b-popover.hover.top="'Imprimir'"
                            ></feather>
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            @change="getAuditorias(1, $event)"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            @input="getAuditorias($event, pagination.perPage)"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
                <selectFieldsReport
                    :modulo="moduleName"
                    :format="selectedFormat"
                    :fields="
                        fieldsAvailables[
                            selectedFormat
                                ? selectedFormat.toLowerCase()
                                : 'excel'
                        ]
                    "
                    :aditionalFields="{ ...body_search }"
                    @close="selectedFormat = null"
                />
                <emailModal
                    :sender="configEmail"
                    :modulo="firstToUpperString(moduleName)"
                    :fieldsAvailables="fieldsAvailables"
                    :extraParamsDatajson="{ ...body_search }"
                />
                <emailModal
                    :id="'email-modal-single'"
                    :sender="configEmail"
                    :modulo="firstToUpperString(moduleName)"
                    :fieldsAvailables="fieldsAvailables"
                    url="seguridad/auditoria/enviar-mail"
                    alternativeOpc="FIND_BY_ID"
                    :extraParamsDatajson="singleEmailParams"
                />
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right mt-4">
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"
                    >
                        <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes('auditoria-query-export') &&
                                !permisos.includes(
                                    'auditoria-query-export-and-email'
                                )
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes('auditoria-query-export')
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'auditoria-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { validateHour, firstToUpperString } from '@/utils/others'
import { setTimeZoneToDate } from '@/utils/date'
import { compareDates, dateNow } from '@/utils/date'
import selectFieldsReport from '@/components/shared/select-fields-report/select-fields-report'
import emailModal from '@/components/shared/email/email'
import print from 'print-js'
// import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const { API } = services

export default {
    name: 'MainAuditoria',
    mounted() {
        this.getAuditorias()
        this.getModulos()
        this.getAvailableFields()
        this.getEmpresaEmails()
    },
    mixins: [validationMixin],
    components: { selectFieldsReport, emailModal },
    data: () => ({
        singleEmailParams: {},
        fields: [
            { key: 'ip', label: 'IP' },
            { key: 'accion', label: 'Acción' },
            { key: 'modulo', label: 'Módulo' },
            { key: 'usuario', label: 'Nombre de usuario' },
            { key: 'fecha', label: 'Fecha y Hora' },
            { key: 'acciones', label: 'Acciones' }
        ],
        body_search: {
            hora_inicio: '01:00:00',
            hora_final: '23:59:00',
            fecha_inicio: dateNow(),
            fecha_final: '',
            modulo_id: null,
            accion: null,
            username: '',
            search_type: 0,
            term: ''
        },
        modulos: [{ id: null, nombre: 'Ninguno' }],
        acciones: [
            { id: null, descripcion: 'Ninguno' },
            { id: 'INSERT', descripcion: 'Insertar' },
            { id: 'UPDATE', descripcion: 'Editar' },
            { id: 'DELETE', descripcion: 'Eliminar' }
        ],
        search_types: [
            { id: 0, descripcion: 'Fecha exacta' },
            { id: 1, descripcion: 'Rango de fechas' }
        ],
        auditorias: [],
        formats: [
            { id: 1, name: 'Generar Informe Excel', ext: 'EXCEL' },
            { id: 2, name: 'Generar Informe Pdf', ext: 'PDF' }
        ],
        fieldsAvailables: {},
        configEmail: null,
        selectedFormat: null,
        moduleName: 'auditoria',
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        search: ''
    }),
    validations: {
        body_search: {
            fecha_inicio: {
                compareDates: (d, a) => {
                    if (a.fecha_final) {
                        return compareDates(d, a.fecha_final)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            fecha_final: {
                compareDates: (d, { fecha_inicio, search_type }) => {
                    if (search_type === 0) {
                        return true
                    } else if (search_type === 1) {
                        return compareDates(fecha_inicio, d)
                    }
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            hora_inicio: {
                valid: (value, { search_type }) => {
                    if (search_type === 0) {
                        const valid = validateHour(value)
                        return valid
                    } else if (search_type === 1) {
                        return true
                    }
                },
                greaterThan: (
                    value,
                    { hora_final, fecha_inicio, search_type }
                ) => {
                    if (search_type === 0) {
                        let date = new Date(fecha_inicio)
                        date.setDate(date.getDate() + 1)
                        // console.log(date)
                        const [hour1, minutes1, seconds1] =
                            hora_final === '00:00:00'
                                ? '24:00:00'.split(':')
                                : hora_final.split(':')
                        const [hour2, minutes2, seconds2] =
                            value === '00:00:00'
                                ? '24:00:00'.split(':')
                                : value.split(':')
                        const ff = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            +hour1,
                            +seconds1,
                            +minutes1
                        )
                        const fi = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            +hour2,
                            +seconds2,
                            +minutes2
                        )
                        return fi < ff
                    } else if (search_type === 1) {
                        return true
                    }
                }
            },
            hora_final: {
                valid: function (value, { search_type }) {
                    if (search_type === 0) {
                        const valid = validateHour(value)
                        return valid
                    } else if (search_type === 1) {
                        return true
                    }
                },
                lessThan: function (
                    value,
                    { hora_inicio, fecha_inicio, search_type }
                ) {
                    if (search_type === 0) {
                        const date = new Date(fecha_inicio)
                        const [hour1, minutes1, seconds1] =
                            value === '00:00:00'
                                ? '24:00:00'.split(':')
                                : value.split(':')
                        const [hour2, minutes2, seconds2] =
                            hora_inicio === '00:00:00'
                                ? '24:00:00'.split(':')
                                : hora_inicio.split(':')
                        const ff = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            +hour1,
                            +seconds1,
                            +minutes1
                        )
                        const fi = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            +hour2,
                            +seconds2,
                            +minutes2
                        )
                        return ff > fi
                    } else if (search_type === 1) {
                        return true
                    }
                }
            },
            modulo_id: {},
            accion: {},
            username: {},
            search_type: {},
            term: {}
        }
    },
    methods: {
        firstToUpperString,
        showLog(data) {
            console.log(data)
        },
        // searchData() {
        //     if (this.search.length >= 3) {
        //         this.getAuditorias(this.search)
        //     } else if (!this.search) {
        //         this.getAuditorias()
        //     }
        // },
        onChangeTypeSearch(search_type) {
            if (search_type === 0) {
                this.body_search.fecha_final = ''
                this.body_search.fecha_inicio = dateNow()
                this.body_search.hora_inicio = '01:00:00'
                this.body_search.hora_final = '23:59:00'
            } else if (search_type === 1) {
                this.body_search.fecha_final = dateNow()
                this.body_search.fecha_inicio = dateNow(-1)
                this.body_search.hora_inicio = ''
                this.body_search.hora_final = ''
            }
        },
        sendSingleAuditoria(auditoria_id) {
            this.singleEmailParams = { id: auditoria_id }
            this.$bvModal.show('email-modal-single')
        },
        getModulos() {
            API.POST({
                url: 'seguridad/auditoria/modulo/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.modulos = [...this.modulos, ...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        printAuditoria(auditoria_id) {
            API.POST({
                url: 'seguridad/auditoria/generate',
                data: {
                    p_datajson: {
                        id: auditoria_id
                    },
                    p_opc: 'FIND_BY_ID_REPORT',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(response.data)
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        const base64Auditoria = dato.replace(
                            'data:application/pdf;base64,',
                            ''
                        )
                        print({
                            printable: base64Auditoria,
                            type: 'pdf',
                            base64: true
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getAuditorias(page = 1, limit = this.pagination.perPage) {
            const valid = !this.$v.body_search.$invalid
            if (valid) {
                API.POST({
                    url: 'seguridad/auditoria/query',
                    data: {
                        p_datajson: {
                            ...this.body_search,
                            page,
                            limit,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'FILTER',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato: { content, totalContent, size, number },
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.auditorias = content
                            this.auditorias = this.auditorias.map((a) => {
                                const d = new Date(a.fecha)

                                return {
                                    ...a,
                                    formated_date: setTimeZoneToDate(d, 'DD-MM-YY, h:mm a')
                                }
                            })
                            this.pagination = {
                                ...this.pagination,
                                totalRows: totalContent,
                                perPage: size,
                                currentPage: number
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.body_search.$touch()
            }
        },
        getAvailableFields() {
            if (this.permisos.includes('auditoria-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        verifyFields(ext) {
            this.selectedFormat = ext
            this.$bvModal.show('modal-fields-report')
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.body_search[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': () =>
                        this.$router.push('/home/general/generales/crear-banco')
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': () =>
                        this.$router.push('/home/general/generales/crear-banco')
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 37 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
